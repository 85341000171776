import {
  HCentredContainer,
  TextBox,
  BoxBorder,
  BoxSolid,
  BoxFront,
  BoxBack,
  SubHeader,
} from "../Page.styles";
import styled from "styled-components";

export const ExperienceContainer = styled(HCentredContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 5vh;
  //border: 3px solid #73AD21;
`;

export const Row = styled.div`
  display: flex;
  //border: 3px solid #73AD21;
  width: 100%;
  justify-content: ${(props) => props?.justify || "flex-start"};

  @media screen and (max-width: 1200px) {
    justify-content: center;
  }
`;

export const ExperienceWrapper = styled.div`
  text-align: left;
  padding-bottom: 5vh;
  width: 60vw;
  max-width: 1000px;
  @media screen and (max-width: 800px) {
    width: 80vw;
  }
`;

export const ExperienceHeaderBox = styled(TextBox)`
  position: relative;
  width: 60vw;
  max-width: 800px;
  //min-width: 50rem;
  //max-width: 100vw;

  @media screen and (max-width: 800px) {
    min-height: 0;
    width: 80vw;
  }
`;

export const ExperienceHeaderBoxFront = styled(ExperienceHeaderBox)`
  ${BoxBorder}
  ${BoxFront}
`;

export const ExperienceHeaderBoxBack = styled(ExperienceHeaderBox)`
  ${BoxSolid};
  ${BoxBack}
`;

export const ExperienceTitle = styled(TextBox)`
  padding: 10px 20px;
`;

export const ExperienceContent = styled(TextBox)`
  padding: 50px 20px 0;
`;

export const ExperienceContentUl = styled.ul`
  list-style: none;
  margin-left: 0;
  padding-left: 0;
`;

export const ExperienceContentLi = styled.li`
  padding-left: 1em;
  text-indent: -1em;

  &:before {
    content: "▸";
    padding-right: 10px;
  }
`;

export const ExperienceDescriptionBox = styled(TextBox)`
  white-space: pre-wrap;
  text-align: left;
  font-family: "Apercu Pro", sans-serif;
  //max-width: 1000px;
`;

export const ExperienceLinkBox = styled(SubHeader)`
  white-space: pre-wrap;
  text-align: left;
  padding: 10px 0;
  max-width: 1000px;
`;
