import {
  SectionHeaderText,
  SectionHeaderBox,
  SectionHeaderDot,
} from "../page/Page.styles";

const SectionHeader = (props) => {
  const { sectionName, isLeft } = props;

  const justifyContent = isLeft ? "flex-start" : "flex-end";

  return (
    <SectionHeaderBox justify={justifyContent}>
      <SectionHeaderText>{sectionName}</SectionHeaderText>
      <SectionHeaderDot>.</SectionHeaderDot>
    </SectionHeaderBox>
  );
};

export default SectionHeader;
