import { MainPage, VCentredContainer, Subtitle } from "./Page.styles";
import styled, { ThemeProvider, keyframes } from "styled-components";
import { themePrimary } from "../style/theme";
import logo from "../assets/logo/logo_white_lg.png";
import { Waypoint } from "react-waypoint";
import TextLink from "../components/TextLink";

const zoomIn = keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const Logo = styled.img`
  transform: translateY(-10vh);
  width: 85%;
  z-index: 1;
  animation: ${zoomIn} ease 2s;
`;

const InfoCard = styled(Subtitle)`
  text-align: center;
  position: absolute;
  transform: translateX(50vw) translateY(6vw);
  animation: ${zoomIn} ease 2s;
  animation-fill-mode: forwards;

  @media screen and (max-width: 1000px) {
    position: relative;
    transform: translateX(0) translateY(0);
  }
`;

const CentreLogoContainer = styled(VCentredContainer)`
  height: 90vh;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const HomePage = (props) => {
  const { setShowBackToTop } = props;
  return (
    <ThemeProvider theme={themePrimary}>
      <MainPage minHeight={"300px"} height={"90vh"}>
        <CentreLogoContainer>
          <Logo src={logo} />
          <Waypoint
            onEnter={(props) => {
              setShowBackToTop(false);
            }}
            onLeave={(props) => {
              setShowBackToTop(true);
            }}
          />
          <InfoCard>
            Comp Sci Undergrad @
            <TextLink
              text={"NUS"}
              href={"https://www.comp.nus.edu.sg/programmes/ug/cs/"}
            />{" "}
            <br />
            / Software Engineer <br />
            Based in Singapore
          </InfoCard>
        </CentreLogoContainer>
      </MainPage>
    </ThemeProvider>
  );
};

export default HomePage;
