export const themePrimary = {
  colors: {
    bg: "#333333",
    fg: "#FFFFFF",
    originalBg: "#333333",
    highlight: "#FFFFFF",
  },
};

export const themeSecondary = {
  colors: {
    bg: "#F5D5DF",
    fg: "#3F549B",
    originalBg: "#333333",
    highlight: "#ECB9BE",
  },
};

export const themeTertiary = {
  colors: {
    bg: "#F7D5CB",
    fg: "#2C819A",
    originalBg: "#F5D5DF",
    highlight: "#8CC5AA",
  },
};

export const title = {
  weight: "900",
  size: "3.5rem",
};

export const subtitle = {
  weight: "900",
  size: "2rem",
};

export const header = {
  weight: "900",
  size: "1.5rem",
};

export const subheader = {
  weight: "bold",
  size: "1.2rem",
};

export const description = {
  weight: "regular",
  size: "1.2rem",
};

export const paragraph = {
  weight: "regular",
  size: "1.1rem",
};
