import { forwardRef } from "react";
import { Waypoint } from "react-waypoint";
import { Description, Header, MainPage, Paragraph } from "../Page.styles";
import {
  ExperienceContainer,
  ExperienceContent,
  ExperienceContentLi,
  ExperienceContentUl,
  ExperienceHeaderBoxBack,
  ExperienceHeaderBoxFront,
  ExperienceLinkBox,
  ExperienceTitle,
  ExperienceWrapper,
  Row,
} from "./ExperiencePage.styles";
import { ThemeProvider } from "styled-components";
import { themeTertiary } from "../../style/theme";
import SectionHeader from "../../components/SectionHeader";
import { ExperiencePageContent } from "./ExperiencePage.content";
import TextLink from "../../components/TextLink";
import { useMediaQuery } from "react-responsive";
import { motion } from "framer-motion";

const ExperiencePage = (props) => {
  const { bg, setBg, expRef } = props;

  return (
    <Waypoint
      bottomOffset="44%"
      topOffset="50%"
      onEnter={(props) => {
        console.log("enter experience");
        setBg(themeTertiary.colors.bg);
      }}
    >
      <ExperienceSectionWithRef ref={expRef} bg={bg} />
    </Waypoint>
  );
};

const ExperienceSectionWithRef = forwardRef((props, ref) => {
  return <ExperienceSection innerRef={ref} {...props} />;
});

const ExperienceSection = (props) => {
  const { innerRef, bg } = props;
  return (
    <ThemeProvider theme={themeTertiary}>
      <MainPage ref={innerRef} background={bg}>
        <SectionHeader sectionName={"Experience"} isLeft={false} />
        <ExperienceContainer>
          <Experience bg={bg} />
        </ExperienceContainer>
      </MainPage>
    </ThemeProvider>
  );
};

const Experience = (props) => {
  const { bg } = props;

  const isMediumScreen = useMediaQuery({ query: "(max-width: 1200px)" });

  const getExperienceTitleStr = (ex) => {
    if (isMediumScreen && ex.location.length > 10) {
      return (
        <Header>
          {ex.title} <br /> @<TextLink text={ex.location} href={ex.link} />
        </Header>
      );
    } else {
      return (
        <Header>
          {ex.title} @<TextLink text={ex.location} href={ex.link} />
        </Header>
      );
    }
  };

  const getExperienceTitleSection = (ex) => {
    return (
      <ExperienceTitle>
        <Description>{ex.time}</Description>
        {getExperienceTitleStr(ex)}
      </ExperienceTitle>
    );
  };

  return ExperiencePageContent.map((ex, idx) => {
    const justifyContent = idx % 2 === 0 ? "flex-start" : "flex-end";
    return (
      <Row key={idx} justify={justifyContent}>
        <ExperienceWrapper>
          <motion.div whileHover={{ scale: 1.1 }}>
            <ExperienceHeaderBoxBack>
              {getExperienceTitleSection(ex)}
            </ExperienceHeaderBoxBack>
            <ExperienceHeaderBoxFront bg={bg}>
              {getExperienceTitleSection(ex)}
            </ExperienceHeaderBoxFront>
          </motion.div>
          <ExperienceContent>
            <Paragraph>
              <ExperienceContentUl>
                {ex.description.map((line, i) => {
                  return (
                    <ExperienceContentLi key={i}>{line}</ExperienceContentLi>
                  );
                })}
              </ExperienceContentUl>
            </Paragraph>
            {ex.projectLinkTitle && (
              <ExperienceLinkBox>
                <TextLink
                  text={ex.projectLinkTitle}
                  isThin={true}
                  href={ex.projectLink}
                />
              </ExperienceLinkBox>
            )}
          </ExperienceContent>
        </ExperienceWrapper>
      </Row>
    );
  });
};

export default ExperiencePage;
