import { ZeroHeightContainer } from "../Page.styles";
import {
  AboutContainer,
  NameBoxWrapper,
  NameTextBoxBack,
  NameTextBoxFront,
  ImageBoxFront,
  ImageBoxBack,
  IntroTextBox,
  ColumnRight,
  ColumnLeft,
  AboutMainPage,
  Image,
} from "./AboutPage.styles";
import { ThemeProvider } from "styled-components";
import { themeSecondary } from "../../style/theme";
import SectionHeader from "../../components/SectionHeader";
import AnimatedQuarterCircle from "../../components/AnimatedQuarterCircle";
import { forwardRef } from "react";
import { Waypoint } from "react-waypoint";
import xinyue from "../../assets/xinyue.gif";
import { motion } from "framer-motion";

const AboutPage = (props) => {
  const { bg, setBg, aboutRef } = props;
  return (
    <Waypoint
      topOffset="60%"
      onEnter={(props) => {
        console.log("enter about");
        setBg(themeSecondary.colors.bg);
      }}
    >
      <AboutSectionWithRef ref={aboutRef} bg={bg} />
    </Waypoint>
  );
};

const AboutSectionWithRef = forwardRef((props, ref) => {
  return <AboutSection innerRef={ref} {...props} />;
});

const AboutSection = (props) => {
  const { innerRef, bg } = props;
  return (
    <ThemeProvider theme={themeSecondary}>
      <ZeroHeightContainer>
        <AnimatedQuarterCircle bg={bg} isLeft={true} />
      </ZeroHeightContainer>
      <AboutMainPage ref={innerRef} background={bg}>
        <SectionHeader sectionName={"About"} isLeft={true} />
        <AboutContainer>
          <ColumnLeft>
            <motion.div whileHover={{ scale: 1.2, rotate: -5 }}>
              <ImageBoxBack />
              <ImageBoxFront bg={bg}>
                <Image src={xinyue} />
              </ImageBoxFront>
            </motion.div>
          </ColumnLeft>
          <ColumnRight>
            <motion.div whileHover={{ scale: 1.2 }}>
              <NameBoxWrapper>
                <NameTextBoxBack />
                <NameTextBoxFront>He Xinyue</NameTextBoxFront>
              </NameBoxWrapper>
            </motion.div>
            <IntroTextBox>
              Hi, my name is Xinyue. I am a year 3 Computer Science
              Undergraduate at the National University of Singapore (NUS). I
              like to build aesthetically-pleasing and user-friendly software
              products, and I draw in my free time :&gt;
            </IntroTextBox>
          </ColumnRight>
        </AboutContainer>
      </AboutMainPage>
    </ThemeProvider>
  );
};

export default AboutPage;
