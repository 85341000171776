import styled from "styled-components";
import { TextBox } from "../page/Page.styles";

const LinkContainer = styled(TextBox)`
  display: inline;
  border-bottom: ${(props) => props.weight} solid
    ${(props) => props.theme.colors.fg};

  &:hover {
    border: 0;
    cursor: pointer;
  }
`;

const Link = styled.a`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

const TextLink = (props) => {
  const { text, isThin, href } = props;
  const getWeight = () => {
    return isThin ? "2px" : "3px";
  };
  return (
    <LinkContainer weight={getWeight()}>
      <Link href={href} target="_blank">
        {text}
      </Link>
    </LinkContainer>
  );
};

export default TextLink;
