import styled from "styled-components";
import { Description, HCentredContainer, MainPage } from "../Page.styles";

export const ContactMainPage = styled(MainPage)`
  padding: 0 12vw;
  background: ${(props) => props.theme.colors.bg};
  min-height: 0;
`;

export const ContactPageDots = styled.img`
  position: relative;
  height: 100px;
  transform: translateY(-50px);
`;

export const ContactPageContainer = styled(HCentredContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: max(10vh, 100px);
  padding-bottom: max(5vh, 50px);
  height: 100%;
  //border: 3px solid #73AD21;
`;

export const ContactLinksBox = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-top: 50px;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
  }
`;

export const ContactLink = styled(Description)`
  padding-right: 20px;
  @media screen and (max-width: 500px) {
    padding: 5px 0;
  }
`;

export const FarewellText = styled.div`
  display: flex;
  align-items: flex-end;
  flex-grow: 2;
  @media screen and (max-width: 500px) {
    padding-bottom: 30px;
  }
`;
