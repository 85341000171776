import styled, { css } from "styled-components";

export const MainPage = styled.div`
  position: relative;
  padding: 5vh 12vw;
  background: ${(props) => props.background};
  color: ${(props) => props.theme.colors.fg};
  height: ${(props) => props.height || "auto"};
  min-height: ${(props) => props.minHeight || "100vh"};
`;

export const VCentredContainer = styled.div`
  display: flex;
  align-items: center;
  //border: 3px solid #73AD21;
`;

export const HCentredContainer = styled.div`
  display: flex;
  justify-content: center;
  //border: 3px solid #73AD21;
`;

export const ZeroHeightContainer = styled.div`
  height: 0;
`;

export const SectionHeaderBox = styled.div`
  display: flex;
  justify-content: ${(props) => props?.justify || "flex-start"};
  padding: 0 10vw;

  @media screen and (max-width: 600px) {
    justify-content: center;
  }
`;

export const SectionHeaderText = styled.div`
  font-weight: 900;
  font-size: 5rem;
  z-index: 2;

  @media screen and (max-width: 500px) {
    font-size: min(4rem, 15vw);
  }
`;

export const SectionHeaderDot = styled(SectionHeaderText)`
  transform: translateY(5px);
  color: ${(props) => props.theme.colors.highlight};
`;

export const TextBox = styled.div`
  font-weight: ${(props) => props.type?.weight || "medium"};
  font-size: ${(props) => props.type?.size};
  z-index: 1;
`;

export const Title = styled(TextBox)`
  font-weight: 900;
  font-size: 3.5rem;

  @media screen and (max-width: 1200px) {
    font-size: 2.5rem;
  }
`;

export const Subtitle = styled(TextBox)`
  font-weight: 900;
  font-size: 2rem;

  @media screen and (max-width: 1350px) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: 600px) {
    font-size: 1.2rem;
  }
`;

export const Header = styled(TextBox)`
  font-weight: 900;
  font-size: 1.5rem;

  @media screen and (max-width: 1350px) {
    font-size: 1.35rem;
  }
  @media screen and (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

export const SubHeader = styled(TextBox)`
  font-weight: bold;
  font-size: 1.3rem;

  @media screen and (max-width: 1350px) {
    font-size: 1.2rem;
  }
`;

export const Description = styled(TextBox)`
  font-weight: 400;
  font-size: 1.3rem;
  text-align: justify;

  @media screen and (max-width: 1350px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 800px) {
    font-size: 0.8rem;
  }
`;

export const Paragraph = styled(TextBox)`
  white-space: pre-wrap;
  text-align: justify;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 1.2rem;

  @media screen and (max-width: 1350px) {
    font-size: 1.1rem;
  }

  @media screen and (max-width: 800px) {
    font-size: 0.8rem;
  }
`;

export const BoxBorder = css`
  color: ${(props) => props.theme.colors.fg};
  background: ${(props) => props.bg};
  border: 3px solid ${(props) => props.theme.colors.fg};
`;

export const BoxSolid = css`
  color: ${(props) => props.theme.colors.bg};
  background: ${(props) => props.theme.colors.fg};
  border: 3px solid ${(props) => props.theme.colors.fg};
`;

export const BoxFront = css`
  z-index: 2;
`;

export const BoxBack = css`
  position: absolute;
  transform: translateX(12px) translateY(12px);
  z-index: 1;
`;

export const QuarterCircle = styled.div`
  position: absolute;
  width: ${(props) => props.radius || "100px"};
  height: ${(props) => props.radius || "100px"};
  //background: green;
  background: ${(props) => props.theme.colors.bg};
`;

export const QuarterCircleLeft = styled(QuarterCircle)`
  border-top-right-radius: ${(props) => props.radius || "100px"};
`;

export const QuarterCircleRight = styled(QuarterCircle)`
  border-top-left-radius: ${(props) => props.radius || "100px"};
`;
