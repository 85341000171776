// import logo from "../assets/logo/logo_white_sm.png"
import "../sass/navbar.scss";
import "../sass/main.scss";
import styled from "styled-components";

const NavBar = styled.nav`
  padding: 10px 12vw;
  height: 10vh;
  min-height: 5rem;
`;

const NavItem = styled.a`
  font-weight: bold;
  font-size: 20px;
`;

const NavBurger = styled.a`
  &:hover {
    background: transparent;
    color: #ecb9be;
  }
`;

const Navbar = (props) => {
  const { aboutRef, expRef, projRef, contactRef } = props;

  const toggleBurger = () => {
    let burgerIcon = document.getElementById("burger");
    let dropMenu = document.getElementById("navBarItemList");
    burgerIcon.classList.toggle("is-active");
    dropMenu.classList.toggle("is-active");
  };

  const toggleDropdown = () => {
    let dropdown = document.getElementById("dropdown");
    dropdown.classList.toggle("is-active");
  };

  return (
    <NavBar
      className="navbar is-28"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        {/*<a className="navbar-item" href="">*/}
        {/*    <img src={logo} width="150"/>*/}
        {/*</a>*/}
        <NavBurger
          id="burger"
          role="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navBarItemList"
          onClick={toggleBurger}
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </NavBurger>
      </div>

      <div id="navBarItemList" className="navbar-menu">
        <div className="navbar-end">
          <NavItem
            className="navbar-item navbar-button"
            onClick={() =>
              aboutRef.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            About
          </NavItem>
          <NavItem
            className="navbar-item"
            onClick={() =>
              expRef.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            Experience
          </NavItem>
          <NavItem
            className="navbar-item"
            onClick={() =>
              projRef.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            Projects
          </NavItem>
          <NavItem
            className="navbar-item"
            onClick={() =>
              contactRef.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            Contacts
          </NavItem>
          <NavItem id="dropdown" className="navbar-item has-dropdown">
            <NavItem className="navbar-link" onClick={toggleDropdown}>
              More
            </NavItem>
            <div className="navbar-dropdown is-right">
              <NavItem
                className="navbar-item"
                href="https://v1.eksinyue.com"
                target="_blank"
              >
                v1.0
              </NavItem>
              <NavItem className="navbar-item" href=".">
                v2.0 (current)
              </NavItem>
              {/*<hr className="navbar-divider"/>*/}
            </div>
          </NavItem>
        </div>
      </div>
    </NavBar>
  );
};

export default Navbar;
