import { useViewportScroll, useTransform, motion } from "framer-motion";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const AnimatedQuarterCircle = (props) => {
  const { bg, isLeft } = props;
  const isSmallScreen = useMediaQuery({ query: "(max-width: 700px)" });

  const getOutputRangeMax = () => {
    if (isSmallScreen) {
      return 20;
    }
    return 10;
  };

  const getRadius = () => {
    if (isSmallScreen) {
      return "60vw";
    }
    return "40vw";
  };

  const getCircleOffset = () => {
    if (isSmallScreen) {
      return "-30vw";
    }
    return "-20vw";
  };

  const QuarterCircle = styled(motion.div)`
    position: relative;
    width: ${(props) => props.radius || "100px"};
    height: ${(props) => props.radius || "100px"};
    border-radius: ${(props) => props.radius || "100px"};
    background: ${(props) => props.bg};
    top: ${(props) => props.offset};
  `;

  const QuarterCircleLeft = styled(QuarterCircle)`
    left: ${(props) => props.offset};
  `;

  const QuarterCircleRight = styled(QuarterCircle)`
    left: 68vw;
  `;

  const { scrollY } = useViewportScroll();
  const scale = useTransform(scrollY, [0, 1000], [1, getOutputRangeMax()]);

  return isLeft ? (
    <QuarterCircleLeft
      id="circle"
      bg={bg}
      radius={getRadius()}
      offset={getCircleOffset()}
      style={{ scale }}
    />
  ) : (
    <QuarterCircleRight radius="40vw" />
  );
};

export default AnimatedQuarterCircle;
