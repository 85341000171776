import doodle2gather from "../../assets/doodle2gather.jpeg";
import artpreciate from "../../assets/artpreciate.png";
import sicp from "../../assets/sicp.jpeg";

export const ProjectPageContent = [
  {
    type: "iOS Application",
    title: "Doodle2Gather",
    image: doodle2gather,
    description:
      "Doodle2Gather is a collaborative whiteboarding iPad app armed with powerful doodling tools, " +
      "infinite canvases and real-time video communication. " +
      "It aims to empower users in their idea generation and discussions online.",
    projectLinkTitle: ["View on GitHub", "View Demo Video"],
    projectLink: [
      "https://github.com/Doodle2Gather/Doodle2Gather",
      "https://www.youtube.com/watch?v=5cVotr76mx4",
    ],
  },
  {
    type: "Web Application",
    title: "ARTpreciate",
    image: artpreciate,
    description:
      "In collaboration with SingHealth, Singapore Art Museum and National Gallery Singapore, " +
      "we created a wellness application that offers a unique art-as-therapy experience through guided " +
      "relaxation and art appreciation.",
    projectLinkTitle: ["View Application", "View Design Mockups"],
    projectLink: [
      "https://www.artpreciate.sg/",
      "https://www.figma.com/file/J5eJNgKRy4w2nwtcMAoRtb/ARTpreciate-final-MockUp-iPhone?node-id=0%3A1",
    ],
  },
  {
    type: "Web Application",
    title: "SICP.Js Textbook",
    image: sicp,
    description:
      "Revamped the web edition of the SICP.JS textbook using Node.js. Developed a Scheme/JavaScript comparison edition of the textbook as a " +
      "tool for proof-reading to support the publishing process of the textbook by MIT Press.",
    projectLinkTitle: ["View Website"],
    projectLink: ["https://sicp.sourceacademy.org/"],
  },
];
