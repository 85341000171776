import styled from "styled-components";
import { themeSecondary, themeTertiary } from "../style/theme";
import { ArrowIosUpwardOutline } from "@styled-icons/evaicons-outline";

const BackToTopCircle = styled.button`
  position: fixed;
  width: 60px;
  height: 60px;
  background: ${(props) => props.color};
  border: none;
  outline: none;
  border-radius: 80px;
  right: 30px;
  bottom: 20px;
  cursor: pointer;
  z-index: 99;
`;

const ArrowIcon = styled(ArrowIosUpwardOutline)`
  color: white;
`;

const BackToTop = (props) => {
  const { bg, showBackToTop } = props;

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const circleColour = () => {
    if (bg === themeSecondary.colors.bg) {
      return themeSecondary.colors.highlight;
    } else {
      return themeTertiary.colors.highlight;
    }
  };

  return (
    showBackToTop && (
      <BackToTopCircle color={circleColour()} onClick={handleClick}>
        <ArrowIcon />
      </BackToTopCircle>
    )
  );
};

export default BackToTop;
