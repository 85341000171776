import { useState, useRef, useEffect } from "react";
import Navbar from "./components/CustomisedNavbar";
import "./sass/main.scss";
import HomePage from "./page/HomePage";
import AboutPage from "./page/about/AboutPage";
import ExperiencePage from "./page/experience/ExperiencePage";
import ProjectPage from "./page/project/ProjectPage";
import ContactPage from "./page/contact/ContactPage";
import { themeSecondary } from "./style/theme";
import BackToTop from "./components/BackToTop";
import { Helmet } from "react-helmet";

const App = () => {
  const [bg, setBg] = useState(themeSecondary.colors.bg);
  const [showBackToTop, setShowBackToTop] = useState(false);

  const aboutRef = useRef(null);
  const expRef = useRef(null);
  const projRef = useRef(null);
  const contactRef = useRef(null);

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
    window.addEventListener(
      "touchmove",
      function (event) {
        if (event.scale !== 1) {
          event.preventDefault();
        }
      },
      { passive: false }
    );
  });

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <title>Xinyue H.</title>
        <meta name="title" content="Xinyue H." />
        <meta
          name="description"
          content="Hi. This is Xinyue, a year 3 Computer Science undergraduate at NUS. Find out more about me here ◡̈ "
        />
      </Helmet>

      <body>
        <Navbar
          aboutRef={aboutRef}
          expRef={expRef}
          projRef={projRef}
          contactRef={contactRef}
        />
        <BackToTop bg={bg} showBackToTop={showBackToTop} />
        <HomePage
          showBackToTop={showBackToTop}
          setShowBackToTop={setShowBackToTop}
        />
        <AboutPage bg={bg} setBg={setBg} aboutRef={aboutRef} />
        <ExperiencePage bg={bg} setBg={setBg} expRef={expRef} />
        <ProjectPage bg={bg} setBg={setBg} projRef={projRef} />
        <ContactPage contactRef={contactRef} />
      </body>
    </>
  );
};

export default App;
