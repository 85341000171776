import { forwardRef } from "react";
import { Description, MainPage, Subtitle } from "../Page.styles";
import {
  Row,
  ProjectContainer,
  ProjectBoxFront,
  ProjectBoxBack,
  ProjectDescriptionBox,
  ProjectBoxContent,
  ProjectLinkBox,
  ProjectLink,
  ProjectBoxContentColumn,
  ProjectImage,
} from "./ProjectPage.styles";
import { ThemeProvider } from "styled-components";
import { themeSecondary } from "../../style/theme";
import SectionHeader from "../../components/SectionHeader";
import { ProjectPageContent } from "./ProjectPage.content";
import { Waypoint } from "react-waypoint";
import TextLink from "../../components/TextLink";
import { motion } from "framer-motion";

const ProjectPage = (props) => {
  const { bg, setBg, projRef } = props;

  return (
    <Waypoint
      bottomOffset="50%"
      onEnter={(props) => {
        console.log("enter project");
        setBg(themeSecondary.colors.bg);
      }}
    >
      <ProjectSectionWithRef ref={projRef} bg={bg} />
    </Waypoint>
  );
};

const ProjectSectionWithRef = forwardRef((props, ref) => {
  return <ProjectSection innerRef={ref} {...props} />;
});

const ProjectSection = (props) => {
  const { innerRef, bg } = props;
  return (
    <ThemeProvider theme={themeSecondary}>
      <MainPage ref={innerRef} background={bg}>
        <SectionHeader sectionName={"Project"} isLeft={true} />
        <ProjectContainer>
          <Project bg={bg} />
        </ProjectContainer>
      </MainPage>
    </ThemeProvider>
  );
};

const Project = (props) => {
  const { bg } = props;

  const getProjectBox = (pj) => {
    return (
      <ProjectBoxContent>
        <ProjectBoxContentColumn>
          <Description>{pj.type}</Description>
          <Subtitle>{pj.title}</Subtitle>
          <ProjectDescriptionBox>{pj.description}</ProjectDescriptionBox>
          <ProjectLinkBox>
            {pj.projectLinkTitle.length > 0 &&
              pj.projectLinkTitle.map((link, idx) => {
                return (
                  <ProjectLink key={idx}>
                    <TextLink
                      text={link}
                      isThin={true}
                      href={pj.projectLink[idx]}
                    />
                  </ProjectLink>
                );
              })}
          </ProjectLinkBox>
        </ProjectBoxContentColumn>
        <ProjectBoxContentColumn>
          <ProjectImage src={pj.image} />
        </ProjectBoxContentColumn>
      </ProjectBoxContent>
    );
  };
  return ProjectPageContent.map((pj, idx) => {
    return (
      <Row key={idx}>
        <motion.div whileHover={{ scale: 1.1 }}>
          <ProjectBoxBack>{getProjectBox(pj)}</ProjectBoxBack>
          <ProjectBoxFront bg={bg}>{getProjectBox(pj)}</ProjectBoxFront>
        </motion.div>
      </Row>
    );
  });
};

export default ProjectPage;
