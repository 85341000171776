export const ExperiencePageContent = [
  {
    time: "May 2021 - July 2021",
    title: "Backend Engineer Intern",
    location: "Shopee",
    link: "https://careers.shopee.sg/",
    description: [
      "Created a code generation Go tool to help the team automatically generate boilerplate code by reading keywords from comments. Significantly reduced merge conflicts among developers.",
      "Developed an API response mocking web application using Golang and React to help the team mock responses from external APIs in non-live environments. Deployed in production.",
    ],
  },
  {
    time: "Jan 2021 - Present",
    title: "Teaching Assistant",
    location: "National University of Singapore",
    link: "https://www.nus.edu.sg/",
    description: [
      "Conducted weekly laboratory sessions and developed curriculum materials to reinforce students' learning.",
      "Guided students through various assignments and provided weekly feedback on their code submissions.",
      "Currently teaching modules CS2030 Programming Methodology II, CS2040S Data Structures & Algorithms and CS3216 Software Product Engineering for Digital Markets.",
    ],
  },
  {
    time: "May 2020 - July 2020",
    title: "Software Engineer",
    location: "Computing For Voluntary Welfare Organistions",
    link: "https://www.comp.nus.edu.sg/~vwo/",
    description: [
      "In a team of five, maintained and upgraded the Lions Befrienders Service Association (LBSA) system, an internal workflow management system used by 16 centres on a daily basis around Singapore.",
      "Launched a new client-facing volunteer app for LBSA, streamlining various workflows for the organisation. Used by 60 - 80 volunteers daily.",
    ],
    projectLinkTitle: "Find out more about what we did",
    projectLink: "https://www.comp.nus.edu.sg/~vwo/projects/2020-lbsa.html",
  },
  {
    time: "Aug 2019 - Sept 2019",
    title: "General Secretary",
    location: "NUS Student's Computing Club",
    link: "https://nuscomputingdev.github.io/nuscomx-test//about/mc.html",
    description: [
      "Coordinated general meetings and enforced discipline within the club.",
      "Sent out weekly newsletters to all students in the NUS School of Computing.",
    ],
  },
];
