import { SubHeader, Subtitle } from "../Page.styles";
import { ThemeProvider } from "styled-components";
import { themePrimary } from "../../style/theme";
import SectionHeader from "../../components/SectionHeader";
import {
  ContactLink,
  ContactLinksBox,
  ContactMainPage,
  ContactPageContainer,
  ContactPageDots,
  FarewellText,
} from "./ContactPage.styles";
import dots from "../../assets/contact_dots.png";
import TextLink from "../../components/TextLink";

const ContactPage = (props) => {
  const { contactRef } = props;
  return (
    <ThemeProvider theme={themePrimary}>
      <ContactMainPage ref={contactRef} minHeight={"500px"} height={"40vh"}>
        <ContactPageDots src={dots} />
        <SectionHeader sectionName={"Contact"} isLeft={false} />
        <ContactPageContainer>
          <Subtitle>
            <TextLink
              text={"XINYUEHE@U.NUS.EDU"}
              href="mailto:xinyuehe@u.nus.edu"
            />
          </Subtitle>
          <ContactLinksBox>
            <ContactLink>
              <TextLink
                text={"GitHub"}
                isThin={true}
                href={"https://github.com/eksinyue"}
              />
            </ContactLink>
            <ContactLink>
              <TextLink
                text={"LinkedIn"}
                isThin={true}
                href={"https://www.linkedin.com/in/eksinyue/"}
              />
            </ContactLink>
            <ContactLink>
              <TextLink
                text={"Alt Email"}
                href={"mailto: xinyuehehxy@gmail.com"}
              />
            </ContactLink>
            {/*<ContactLink>*/}
            {/*    <TextLink text={"Resume"} isThin={true} href={""}/>*/}
            {/*</ContactLink>*/}
          </ContactLinksBox>
          <FarewellText>
            <SubHeader>Thanks for visiting :)</SubHeader>
          </FarewellText>
        </ContactPageContainer>
      </ContactMainPage>
    </ThemeProvider>
  );
};

export default ContactPage;
