import {
  HCentredContainer,
  TextBox,
  BoxBorder,
  BoxSolid,
  BoxFront,
  BoxBack,
  Paragraph,
  Title,
} from "../Page.styles";
import styled from "styled-components";

export const AboutMainPage = styled.div`
  padding: 15vh 12vw 10vh;
  background: ${(props) => props.theme.colors.originalBg};
  color: ${(props) => props.theme.colors.fg};
  min-height: 80vh;
`;

export const AboutContainer = styled(HCentredContainer)`
  align-items: flex-start;
  //border: 3px solid #73AD21;
  min-height: 60vh;

  @media screen and (max-width: 1000px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  //border: 3px solid #73AD21;
`;

export const ColumnLeft = styled(Column)`
  justify-content: center;
  align-items: flex-end;
  padding: 5vh 5vw;

  //@media screen and (max-width: 1000px) {
  //  padding: 5vh;
  //}
`;

export const ColumnRight = styled(Column)`
  justify-content: center;
  align-items: flex-end;
  padding: 0 20px 0 0;
`;

export const ImageBox = styled(TextBox)`
  position: relative;
  width: 22rem;
  height: 24.9rem;

  @media screen and (max-width: 1200px) {
    width: 15rem;
    height: 16.9rem;
  }
`;

export const ImageBoxFront = styled(ImageBox)`
  ${BoxBorder}
  ${BoxFront}
`;

export const ImageBoxBack = styled(ImageBox)`
  ${BoxSolid};
  ${BoxBack}
`;

export const Image = styled.img``;

export const NameBoxWrapper = styled.div`
  transform: rotate(5deg);
`;

export const NameTextBox = styled(Title)`
  position: relative;
  text-align: center;
  width: 20rem;
  height: 5rem;

  @media screen and (max-width: 1200px) {
    width: 15rem;
    height: 4rem;
  }
`;

export const NameTextBoxFront = styled(NameTextBox)`
  ${BoxSolid}
  ${BoxFront}
`;

export const NameTextBoxBack = styled(NameTextBox)`
  ${BoxBorder};
  ${BoxBack}
`;

export const IntroTextBox = styled(Paragraph)`
  text-align: justify;
  padding-top: 100px;
  max-width: 500px;

  @media screen and (max-width: 1000px) {
    text-align: justify;
    padding-top: 50px;
    width: 60vw;
  }
`;
