import styled from "styled-components";
import {
  BoxBack,
  BoxBorder,
  BoxFront,
  BoxSolid,
  HCentredContainer,
  Paragraph,
  SubHeader,
} from "../Page.styles";
import { TextBox } from "../Page.styles";

export const ProjectContainer = styled(HCentredContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5vh;
  padding-bottom: max(5vh, 50px);
  //border: 3px solid #73AD21;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 10vh;
  //border: 3px solid #73AD21;
`;

export const ProjectBox = styled(TextBox)`
  position: relative;
  width: 60vw;
  aspect-ratio: 2;
  max-width: 900px;

  @media screen and (max-width: 1200px) {
    aspect-ratio: 1.7;
    width: 65vw;
  }

  @media screen and (max-width: 1000px) {
    width: 70vw;
  }

  @media screen and (max-width: 800px) {
    aspect-ratio: auto;
  }
`;

export const ProjectBoxFront = styled(ProjectBox)`
  ${BoxBorder}
  ${BoxFront}
`;

export const ProjectBoxBack = styled(ProjectBox)`
  ${BoxSolid};
  ${BoxBack}
`;

export const ProjectBoxContent = styled.div`
  display: flex;
  padding: 20px;
  height: 100%;

  @media screen and (max-width: 800px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const ProjectBoxContentColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 55%;

  @media screen and (max-width: 800px) {
    max-width: 90%;
  }
`;

export const ProjectDescriptionBox = styled(Paragraph)`
  padding: 10px 0;
  width: 35vw;
  max-width: 100%;
  font-size: 1.1rem;

  @media screen and (max-width: 1350px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 1000px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
  }
`;

export const ProjectLinkBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-grow: 2;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    flex-grow: 0;
  }
`;

export const ProjectLink = styled(SubHeader)`
  padding-right: 15px;

  @media screen and (max-width: 1000px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 800px) {
    padding: 3px 0;
  }

  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
  }
`;

export const ProjectImage = styled.img`
  padding: 0 10px 0 50px;

  @media screen and (max-width: 800px) {
    padding: 30px 50px;
  }

  @media screen and (max-width: 600px) {
    padding: 30px 20px;
  }
`;
